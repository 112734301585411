var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ScreeningsUpdate"},[_c('DotMenu',{attrs:{"use-component":_vm.ScreeningMenu,"dataToComponent":{
      isScreeningComplete:
        _vm.currentScreening
        && _vm.currentScreening.data
        && _vm.currentScreening.data.isCompleted
    }}}),_c('ScreeningHeader'),(!_vm.isFirstStepDone)?_c('div',{staticClass:"FirstSteps"},[_c('p',[_vm._v(_vm._s(_vm.mixWB('FILL_OUT_THESE')))]),(!_vm.projectType)?_c('router-link',{staticClass:"FirstStepLink",attrs:{"to":{
        name: 'ScreeningsUpdateProjectDetails',
        params: { screeningID: this.$route.params.screeningID }
      }}},[_c('IconButton',{attrs:{"icon":"text","text":_vm.mixWB('ADD_PROJECT_DETAILS'),"isRequired":true}})],1):_vm._e(),(!_vm.noOfBuildings)?_c('router-link',{staticClass:"FirstStepLink",attrs:{"to":{
        name: 'ScreeningsUpdateBuildings',
        params: { screeningID: this.$route.params.screeningID }
      }}},[_c('IconButton',{attrs:{"icon":"house","text":_vm.mixWB('ADD_UNITS'),"isRequired":true}})],1):_vm._e(),(!_vm.builderFullName)?_c('router-link',{staticClass:"FirstStepLink",attrs:{"to":{
        name: 'ScreeningsUpdateBuilder',
        params: { screeningID: this.$route.params.screeningID }
      }}},[_c('IconButton',{attrs:{"icon":"user","text":_vm.mixWB('ADD_BUILDER')}})],1):_vm._e(),(!_vm.currentScreeningTenderDetails.selectedQuestionIDs.length)?_c('router-link',{staticClass:"FirstStepLink",attrs:{"to":{
        name: 'ScreeningsUpdateTenderDetails',
        params: { screeningID: this.$route.params.screeningID }
      }}},[_c('IconButton',{attrs:{"icon":"question","text":_vm.mixWB('TENDER_DETAILS')}})],1):_vm._e()],1):_vm._e(),_c('ScreeningsUpdateFlow')],1)}
var staticRenderFns = []

export { render, staticRenderFns }