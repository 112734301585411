<template>
  <div class="AutoAssessmentOverview">
    <span class="MainNumber">{{ assessmentCount }}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AutoAssessmentOverview',
  computed: {
    ...mapGetters([
      'screeningTypes',
    ]),
    assessmentCount() {
      return this.screeningTypes.reduce((prev, type) => {
        prev += type.assessments.assessmentIDs.length
        return prev
      }, 0)
    },
  },
}
</script>

<style lang="stylus" scoped>
  .AutoAssessmentOverview
    span
      display block

  .MainNumber
      width 100%
      padding 10px
      margin-bottom 10px
      color $color_grey
      background-color $color_grey_lightest
      text-align center
      font-size 1.5rem
</style>
