<template>
  <div class="SamplesOverview">
    <span class="MainNumber">
      {{
        sampleNumbers.samples
      }} {{
        sampleNumbers.samples === 1 ? mixWB('SAMPLE') : mixWB('SAMPLES')
      }}
    </span>
    <span class="MainNumber">
      {{
        sampleNumbers.analyzes
      }} {{
        sampleNumbers.analyzes === 1 ? mixWB('ANALYSIS') : mixWB('ANALYZES')
      }}
    </span>
    <span class="SampleItem">{{ mixWB('SAMPLE_ASBEST') }}: {{ sampleNumbers.asbestos }}</span>
    <span class="SampleItem">{{ mixWB('SAMPLE_7_METALS') }}: {{ sampleNumbers.heavyMetals }}</span>
    <span class="SampleItem">{{ mixWB('SAMPLE_PAHS') }}: {{ sampleNumbers.pah }}</span>
    <span class="SampleItem">{{ mixWB('SAMPLE_PCB') }}: {{ sampleNumbers.pcb }}</span>
    <span class="SampleItem">{{ mixWB('SAMPLE_KPS') }}: {{ sampleNumbers.kp }}</span>
  </div>
</template>

<script>
import { checkToAddPCBToType } from '@/globals/javascript/_util/samples'
import { mapGetters } from 'vuex'

export default {
  name: 'SamplesOverview',
  computed: {
    ...mapGetters([
      'screeningTypes',
      'screeningSamples',
      'screeningPCBScreenings',
    ]),
    sampleNumbers() {
      const data = {
        samples: 0,
        analyzes: 0,
        asbestos: 0,
        heavyMetals: 0,
        pah: 0,
        pcb: 0,
        kp: 0,
      }

      const samplesToBeTaken = this.screeningSamples.filter((x) => x.status === 'done')

      data.samples = samplesToBeTaken.length
      data.analyzes = samplesToBeTaken.reduce((prev, sample) => {
        const type = this.screeningTypes.find((x) => x.id === sample.typeID)

        if (!type) {
          return prev
        }

        let sampleIDs = []
        if (sample.kindOfSample === 'material') {
          sampleIDs = type.samples.sampleIDs
        }
        if (sample.kindOfSample === 'coating') {
          sampleIDs = type.coating.samples.sampleIDs
        }
        if (sample.kindOfSample === 'underlining') {
          sampleIDs = type.underlining.samples.sampleIDs
        }

        // Add PCB to list if needed
        const addPCBAnalysis = checkToAddPCBToType(
          type,
          this.screeningPCBScreenings,
          sample.kindOfSample,
        )
        if (addPCBAnalysis) {
          prev += 1
        }

        if (sampleIDs.includes('ST-1')) {
          data.asbestos += 1
        }
        if (sampleIDs.includes('ST-2')) {
          data.heavyMetals += 1
        }
        if (sampleIDs.includes('ST-3')) {
          data.pah += 1
        }
        if (sampleIDs.includes('ST-4') || addPCBAnalysis) {
          data.pcb += 1
        }
        if (sampleIDs.includes('ST-5')) {
          data.kp += 1
        }

        prev += sampleIDs.length
        return prev
      }, 0)

      return data
    },
  },
}
</script>

<style lang="stylus" scoped>
  .SamplesOverview
    margin-bottom 20px
    display flex
    flex-wrap wrap
    justify-content space-between
    span
      display block
    .MainNumber
      width calc(50% - 5px)
      padding 10px
      margin-bottom 10px
      color $color_grey
      background-color $color_grey_lightest
      text-align center
      font-size 1.5rem
    .SampleItem
      width calc(50% - 5px)
      padding 10px
      margin-bottom 10px
      color $color_grey
      background-color $color_grey_lightest
      text-align center
</style>
