<template>
  <div class="ScreeningsUpdate">
    <DotMenu
      :use-component="ScreeningMenu"
      :dataToComponent="{
        isScreeningComplete:
          currentScreening
          && currentScreening.data
          && currentScreening.data.isCompleted
      }"/>

    <ScreeningHeader />

    <!-- First steps -->
    <div
      class="FirstSteps"
      v-if="!isFirstStepDone">
      <p>{{ mixWB('FILL_OUT_THESE') }}</p>
      <router-link
        v-if="!projectType"
        class="FirstStepLink"
        :to="{
          name: 'ScreeningsUpdateProjectDetails',
          params: { screeningID: this.$route.params.screeningID }
        }">
        <IconButton
          icon="text"
          :text="mixWB('ADD_PROJECT_DETAILS')"
          :isRequired="true" />
      </router-link>
      <router-link
        v-if="!noOfBuildings"
        class="FirstStepLink"
        :to="{
          name: 'ScreeningsUpdateBuildings',
          params: { screeningID: this.$route.params.screeningID }
        }">
        <IconButton
          icon="house"
          :text="mixWB('ADD_UNITS')"
          :isRequired="true" />
      </router-link>
      <router-link
        v-if="!builderFullName"
        class="FirstStepLink"
        :to="{
          name: 'ScreeningsUpdateBuilder',
          params: { screeningID: this.$route.params.screeningID }
        }">
        <IconButton
          icon="user"
          :text="mixWB('ADD_BUILDER')" />
      </router-link>
      <router-link
        v-if="!currentScreeningTenderDetails.selectedQuestionIDs.length"
        class="FirstStepLink"
        :to="{
          name: 'ScreeningsUpdateTenderDetails',
          params: { screeningID: this.$route.params.screeningID }
        }">
        <IconButton
          icon="question"
          :text="mixWB('TENDER_DETAILS')" />
      </router-link>
    </div>

    <ScreeningsUpdateFlow />
  </div>
</template>

<script>
import DotMenu from '@/components/DotMenu/DotMenu.vue'
import IconButton from '@/components/Buttons/IconButton.vue'
import ScreeningHeader from '@/components/ScreeningItems/ScreeningHeader.vue'
import ScreeningMenu from '@/components/ScreeningItems/Menus/ScreeningMenu.vue'
import ScreeningsUpdateFlow from '@/views/ScreeningsUpdateFlow.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ScreeningsUpdate',
  data() {
    return {
      ScreeningMenu,
      isStarting: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentScreening',
      'projectType',
      'noOfBuildings',
      'builderFullName',
      'currentScreeningTenderDetails',
      'isFirstStepDone',
    ]),
  },
  methods: {
    onStartClick() {
      this.isStarting = true
      this.$store.dispatch('updateScreening', { setAsStarted: true })
    },
  },
  components: {
    DotMenu,
    IconButton,
    ScreeningHeader,
    ScreeningsUpdateFlow,
  },
}
</script>

<style lang="stylus" scoped>
  .ScreeningsUpdate
    pageWrap()

  .FirstStepLink
    display block
    margin-bottom 10px

</style>
