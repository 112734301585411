<template>
  <div class="UnitSelectionModal">
    <span class="Title">{{ mixWB('SELECT_UNIT') }}</span>

    <div class="ButtonWrap">
      <Button
        :text="mixWB('SHOW_FOR_ALL_UNITS')"
        :outlined="!!currentScreeningSelectedFilterUnitID"
        @button-click="onSeeAllClick"/>
    </div>

    <div class="UnitList">
      <UnitItemHorizontal
        v-for="unit in selectedUnits"
        :key="unit.id"
        :unit="unit"
        :isUnitSelected="unit.id === currentScreeningSelectedFilterUnitID"
        @unit-click="onUnitClick" />
    </div>

    <div
      class="CadastralMapImageWrap"
      v-if="cadastralMapImage">
      <span class="MapTitle">{{ mixWB('CADASTRAL_MAP') }}</span>
      <MultiImage :image="cadastralMapImage" />
    </div>
  </div>
</template>

<script>
import UnitItemHorizontal from '@/components/ScreeningTypeSections/UnitItemHorizontal.vue'
import Button from '@/components/Buttons/Button.vue'
import MultiImage from '@/components/Images/MultiImage.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'UnitSelectionModal',
  computed: {
    ...mapGetters([
      'selectedUnits',
      'addressImages',
      'currentScreeningSelectedFilterUnitID',
    ]),
    cadastralMapImage() {
      if (this.addressImages?.overview?.cadastralMap?.length) {
        return this.addressImages.overview.cadastralMap[
          this.addressImages.overview.cadastralMap.length - 1
        ]
      }

      return false
    },
  },
  methods: {
    onSeeAllClick() {
      this.$emit('pass-to-parent', { name: 'see-all-click' })
    },
    onUnitClick(unitID) {
      this.$emit('pass-to-parent', { name: 'unit-click', value: unitID })
    },
  },
  components: {
    UnitItemHorizontal,
    Button,
    MultiImage,
  },
}
</script>

<style lang="stylus" scoped>
  .UnitSelectionModal
    span
      display block

  .Title
    modalTitle()

  .ButtonWrap
    margin-bottom 20px

  .CadastralMapImageWrap
    margin-top 20px
    .MapTitle
      display block
      font-size 0.875rem
      padding-bottom 2px
</style>
