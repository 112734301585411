<template>
  <div class="ScreeningsUpdateFlow">

    <!-- Rest of the steps -->
    <template v-if="isFirstStepDone">
      <!-- Unit selection -->
      <div
        v-if="selectedUnits.length > 1"
        class="UnitFilterWrap"
        @click="onSelectUnitClick">
        <div
          class="LeftSide"
          v-if="currentScreeningSelectedFilterUnitID">
          <span class="TopText">{{ mixWB('ONLY_SHOW_FOR') }}</span>
          <span class="UnitTitle">{{ currentScreeningSelectedFilterUnitTitle }}</span>
        </div>
        <div
          class="LeftSide"
          v-else>
          <span class="TopText">{{ mixWB('SHOW_FOR') }}</span>
          <span class="UnitTitle">{{ mixWB('ALL_UNITS') }}</span>
        </div>
        <div class="RightSide">
          <Button
            :text="mixWB('CHANGE')"
            :fullWidth="false" />
        </div>
      </div>

      <!-- Next step button -->
      <div
        v-if="nextStep.text"
        class="ContinueButtonWrap">
        <NextStepButton :title="mixWB('NEXT')" />
      </div>

      <!-- Sreening - Outside -->
      <span class="SectionTitle">{{ mixWB('OUTSIDE') }}</span>
      <div class="CategoryList">
        <CategoryItem
          v-for="item in mutatedCategories.outside"
          :key="item.id"
          :item="item" />
      </div>

      <!-- Screening - Inside -->
      <span class="SectionTitle">{{ mixWB('INSIDE') }}</span>
      <div class="CategoryList">
        <CategoryItem
          v-for="item in mutatedCategories.inside"
          :key="item.id"
          :item="item" />
      </div>

      <!-- Screening - Other -->
      <span class="SectionTitle">{{ mixWB('MISC') }}</span>
      <div class="CategoryList">
        <CategoryItem
          v-for="item in mutatedCategories.other"
          :key="item.id"
          :item="item" />
      </div>

      <!-- PCB screening button -->
      <div class="PageSection">
        <span class="SectionTitle">{{ mixWB('PCB_SCREENINGS') }}</span>
        <div
          v-if="!pcbScreeningStatus.total"
          class="NoPCBScreeningsNeeded">
          <span>{{ mixWB('NO_PCBSCREENINGS_ARE_REQUIRED') }}</span>
        </div>

        <Button
          v-else
          :text="pcbScreeningButtonText"
          :path="{
            name: 'ScreeningsUpdatePSCScreeningSchema',
            params: { screeningID: currentScreening.id }
          }" />
      </div>

      <!-- Take samples button -->
      <div class="PageSection">
        <span class="SectionTitle">{{ mixWB('SAMPLING') }}</span>
        <div
          v-if="!samplesStatus.total"
          class="NoSamplesNeeded">
          <span>{{ mixWB('NO_SAMPLES_ARE_REQUIRED') }}</span>
        </div>
        <Button
          v-else
          :text="takeSamplesButtonText"
          :path="{
            name: 'ScreeningsUpdateSamples',
            params: { screeningID: currentScreening.id }
          }"/>
      </div>

      <!-- Address Profile images -->
      <div class="PageSection">
        <span class="SectionTitle">{{ mixWB('PROFILE_IMAGES') }}</span>
        <Button
          :text="profileImagesText"
          :path="{
            name: 'ScreeningsUpdateProfileImages',
            params: { screeningID: this.$route.params.screeningID }
          }" />
      </div>

      <!-- Building floor plans -->
      <div class="PageSection">
        <span class="SectionTitle">{{ mixWB('FLOOR_PLANS') }}</span>
        <Button
          :text="floorPlansText"
          :path="{
            name: 'ScreeningsUpdateFloorPlans',
            params: { screeningID: this.$route.params.screeningID }
          }" />
      </div>

      <!-- Resale overview -->
      <div class="ResaleOverview">
        <span class="SectionTitle">{{ mixWB('RESALE_OVERVIEW') }}</span>
        <div class="ResaleTable">
          <span>{{ mixWB('YES') }}: {{ screeningResaleStatus.yes }}</span>
          <span>{{ mixWB('POTENTIAL') }}: {{ screeningResaleStatus.potential }}</span>
        </div>
      </div>

      <!-- Samples overview -->
      <span class="SectionTitle">{{ mixWB('DONE_SAMPLES') }}</span>
      <SamplesOverview />

      <!-- Auto assessment overview -->
      <span class="SectionTitle">{{ mixWB('AUTOMATIC_ASSESSMENTS') }}</span>
      <AutoAssessmentOverview/>
    </template>

    <Dialog
      :isShowing="showUnitSelectionModal"
      :useComponent="UnitSelectionModal"
      @close="showUnitSelectionModal = false"
      @see-all-click="onSeeAllUnitsClick"
      @unit-click="onUnitClick" />
  </div>
</template>

<script>
import Button from '@/components/Buttons/Button.vue'
import { mapGetters } from 'vuex'
import CategoryItem from '@/components/ScreeningItems/CategoryItem.vue'
import NextStepButton from '@/components/ScreeningItems/NextStepButton.vue'
import ScreeningMenu from '@/components/ScreeningItems/Menus/ScreeningMenu.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import UnitSelectionModal from '@/components/ScreeningItems/UnitSelectionModal.vue'
import SamplesOverview from '@/components/ScreeningItems/SamplesOverview.vue'
import AutoAssessmentOverview from '@/components/ScreeningItems/AutoAssessmentOverview.vue'

export default {
  name: 'ScreeningsUpdate',
  data() {
    return {
      ScreeningMenu,
      UnitSelectionModal,
      showUnitSelectionModal: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentScreening',
      'isFirstStepDone',
      'categories',
      'screeningTypes',
      'profileImageStatus',
      'floorPlanStatus',
      'pcbScreeningStatus',
      'samplesStatus',
      'screeningResaleStatus',
      'nextStep',
      'currentScreeningSelectedFilterUnitID',
      'selectedUnits',
      'skippedCategories',
    ]),
    currentScreeningSelectedFilterUnit() {
      if (!this.currentScreeningSelectedFilterUnitID) {
        return false
      }
      return this.selectedUnits.find(
        (x) => x.id === this.currentScreeningSelectedFilterUnitID,
      )
    },
    currentScreeningSelectedFilterUnitTitle() {
      if (!this.currentScreeningSelectedFilterUnit) {
        return ''
      }

      return this.currentScreeningSelectedFilterUnit.getTitle()
    },
    mutatedCategories() {
      return this.categories.reduce((prev, category) => {
        const mutatedCategory = {
          ...category,
          status: {
            text: '',
            isApproved: false,
          },
          sampleCount: 0,
          assessmentCount: 0,
        }

        // Find types for this category
        let types = []
        if (this.currentScreeningSelectedFilterUnitID) {
          types = this.screeningTypes.filter(
            (x) => x.categoryID === category.id
            && x.unitIDs.includes(this.currentScreeningSelectedFilterUnitID),
          )
        }
        else {
          types = this.screeningTypes.filter(
            (x) => x.categoryID === category.id,
          )
        }

        // Find types for connected categories
        if (category.connection && this.currentScreeningSelectedFilterUnitID) {
          const connectedTypes = this.screeningTypes.filter(
            (x) => category.connection.categoryIDs.includes(x.categoryID)
            && category.connection.categoryTypeIDs.includes(x.type.typeID)
            && x.unitIDs.includes(this.currentScreeningSelectedFilterUnitID),
          )
          types = types.concat(connectedTypes)
        }
        else if (category.connection) {
          const connectedTypes = this.screeningTypes.filter(
            (x) => category.connection.categoryIDs.includes(x.categoryID)
            && category.connection.categoryTypeIDs.includes(x.type.typeID),
          )
          types = types.concat(connectedTypes)
        }

        // Find samples and assessments (omitting resale === 'yes')
        mutatedCategory.sampleCount = types.reduce((prev, type) => {
          if (type.resale === 'yes') {
            return prev
          }
          if (type.samples.items.length) {
            prev += 1
          }
          if (type.coating.samples.items.length) {
            prev += 1
          }
          if (type.underlining.samples.items.length) {
            prev += 1
          }
          return prev
        }, 0)
        mutatedCategory.assessmentCount = types.filter(
          (x) => x.assessments.items.length && x.resale !== 'yes',
        ).length

        // Set skipped status
        let isSkipped = this.skippedCategories.general.includes(category.id)
        if (!isSkipped && this.currentScreeningSelectedFilterUnitID) {
          isSkipped = this.skippedCategories[
            this.currentScreeningSelectedFilterUnitID
          ].includes(category.id)
        }
        if (!isSkipped) {
          isSkipped = !Object.keys(this.skippedCategories).find(
            (key) => key !== 'general' && !this.skippedCategories[key].includes(category.id),
          )
        }

        // Set approved status
        if (types.length) {
          mutatedCategory.status.text = `${ types.length } ${ types.length === 1 ? this.mixWB('TYPE').toLowerCase() : this.mixWB('TYPES').toLowerCase() }`
          mutatedCategory.status.isApproved = true
        }
        else if (isSkipped) {
          mutatedCategory.status.text = this.mixWB('SKIPPED')
          mutatedCategory.status.isApproved = true
        }
        else {
          mutatedCategory.status.text = this.mixWB('NOT_FILLED')
          mutatedCategory.status.isApproved = false
        }

        // Add to correct place
        if (category.place === 'outside') {
          prev.outside.push(mutatedCategory)
          return prev
        }
        if (category.place === 'inside') {
          prev.inside.push(mutatedCategory)
          return prev
        }
        prev.other.push(mutatedCategory)
        return prev
      }, {
        outside: [],
        inside: [],
        other: [],
      })
    },
    pcbScreeningButtonText() {
      if (this.pcbScreeningStatus.done !== this.pcbScreeningStatus.total) {
        return `${ this.mixWB('PERFORM_PCB_SCREENINGS') } (${ this.pcbScreeningStatus.done }/${ this.pcbScreeningStatus.total })`
      }

      return `${ this.mixWB('ALL_PCB_DONE') } (${ this.pcbScreeningStatus.done }/${ this.pcbScreeningStatus.total })`
    },
    takeSamplesButtonText() {
      if (this.samplesStatus.done !== this.samplesStatus.total) {
        return `${ this.mixWB('TAKE_SAMPLES') } (${ this.samplesStatus.done }/${ this.samplesStatus.total })`
      }
      return `${ this.mixWB('ALL_SAMPLING_IS_DONE') } (${ this.samplesStatus.done }/${ this.samplesStatus.total })`
    },
    profileImagesText() {
      if (this.profileImageStatus.done !== this.profileImageStatus.total) {
        return `${ this.mixWB('TAKE_IMAGES') } (${ this.profileImageStatus.done }/${ this.profileImageStatus.total })`
      }

      return `${ this.mixWB('ALL_IMAGES_ARE_DONE') } (${ this.profileImageStatus.done }/${ this.profileImageStatus.total })`
    },
    floorPlansText() {
      if (this.floorPlanStatus.done !== this.floorPlanStatus.total) {
        return `${ this.mixWB('UPLOAD_FLOOR_PLANS') } (${ this.floorPlanStatus.done }/${ this.floorPlanStatus.total })`
      }

      return `${ this.mixWB('ALL_FLOOR_PLANS_ARE_DONE') } (${ this.floorPlanStatus.done }/${ this.floorPlanStatus.total })`
    },
  },
  methods: {
    onSelectUnitClick() {
      this.showUnitSelectionModal = true
    },
    onSeeAllUnitsClick() {
      this.showUnitSelectionModal = false
      this.$store.dispatch('setCurrentScreeningSelectedFilterUnitID', null)
    },
    onUnitClick(unitID) {
      this.showUnitSelectionModal = false
      this.$store.dispatch('setCurrentScreeningSelectedFilterUnitID', unitID)
    },
    onContinueButtonClick() {
      if (this.nextStep.action === 'complete-screening') {
        // Timeout to not see text for when completed
        this.$store.dispatch('updateScreening', { setAsCompleted: true })
        this.$router.push(this.nextStep.path)
        return
      }

      this.$router.push(this.nextStep.path)
    },
  },
  components: {
    Button,
    CategoryItem,
    NextStepButton,
    SamplesOverview,
    Dialog,
    AutoAssessmentOverview,
  },
}
</script>

<style lang="stylus" scoped>
  .UnitFilterWrap
    display flex
    justify-content space-between
    align-items center
    padding-bottom 10px
    margin-top -5px
    margin-bottom 5px
    border-bottom 1px solid $color_grey_lighter
    span
      display block
    .TopText
      font-size 0.75rem
      color $color_grey
    .UnitTitle
      text-transform uppercase
      font-weight bold
      color $color_grey
    .UnitType
      font-size 0.875rem
      color $color_grey
      font-weight bold
  .ContinueButtonWrap
    padding-top 10px
    margin-bottom 20px

  .PageSection
    margin-bottom 20px

  .SectionTitle
    display block
    margin-bottom 10px
    font-weight bold
    text-align center
    text-transform uppercase

  .CategoryList
    display flex
    flex-wrap wrap
    margin 0 -5px 20px

  .NoSamplesNeeded, .NoPCBScreeningsNeeded
    padding 15px
    border 1px solid $color_grey_lighter
    background-color $color_grey_lightest

  .ResaleOverview
    margin-bottom 20px
    .ResaleTable
      display flex
      margin-left -5px
      margin-right -5px
      span
        margin 5px
        padding 10px
        color $color_grey
        background-color $color_grey_lightest
        width 50%
        text-align center

</style>
