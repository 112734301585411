<template>
  <div
    class="UnitItemHorizontal"
    :class="{ 'IsUnitSelected': isUnitSelected }"
    @click="onUnitClick">
    <div
      v-if="unitProfileImage"
      class="ImageWrap">
      <MultiImage :image="unitProfileImage" />
    </div>

    <div class="InfoWrap">
      <span class="Title">{{ unit.getFullTitle() }}</span>
      <span class="Type">({{ unit.getTypeText() }})</span>
      <span v-if="unit.type === 'building' && unit.getBuildingTypeCodeText()">
        {{ unit.getBuildingTypeCodeText() }}
      </span>
      <span v-if="unit.type === 'building'">
        {{ mixWB('BUILT_AREA') }}: {{ unit.getBuiltArea() }} {{ mixWB('M2') }}
      </span>
      <span v-if="unit.type === 'building'">
        {{ mixWB('YEAR_BUILT') }}: {{ unit.getYearBuilt() }}
      </span>
    </div>
  </div>
</template>

<script>
import MultiImage from '@/components/Images/MultiImage.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'UnitItemHorizontal',
  props: {
    unit: {
      type: Object,
      required: true,
    },
    isUnitSelected: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      'addressImages',
    ]),
    unitProfileImage() {
      if (!this.addressImages?.units?.[this.unit.id]?.profile) {
        return false
      }

      return this.addressImages.units[this.unit.id].profile[0]
    },
  },
  methods: {
    onUnitClick() {
      this.$emit('unit-click', this.unit.id)
    },
  },
  components: {
    MultiImage,
  },
}
</script>

<style lang="stylus" scoped>
  .UnitItemHorizontal
    display flex
    margin-bottom 10px
    align-items center
    cursor pointer
    padding 10px
    background-color $color_grey_lightest
    border 1px solid lighten($color_grey_lighter, 25%)
    box-shadow $box_shadow_1
    &:last-child
      margin-bottom 0px
    span
      display block
    &.IsUnitSelected
      background-color $color_primary_lightest
      border-color $color_primary_lightest
      box-shadow $box_shadow_1

  .ImageWrap
    box(70px)
    margin-right 10px

  .InfoWrap
    span
      font-size 0.75rem
    .Title
      text-transform uppercase
      font-weight bold
</style>
